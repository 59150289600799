<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="slotProps">
    <a-form-item
      :label="$attrs.label"
      :label-col="{ md: { span: labelCol } }"
      :wrapper-col="{ md: { span: wrapperCol } }"
      hasFeedback
      :validateStatus="resolveState(slotProps)"
      :help="slotProps.errors[0]"
    >
      <a-input :type="$attrs.type" :placeholder="$attrs.placeholder" v-model="innerValue" />
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    labelCol: {
      type: Number
    },
    wrapperCol: {
      type: Number
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    resolveState({ errors, pending, valid }) {
      if (errors[0]) {
        return "error";
      }

      if (pending) {
        return "validating";
      }

      if (valid) {
        return "success";
      }

      return "";
    }
  }
};
</script>
