

































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import InputWithValidation from "@/components/inputs/InputWithValidation.vue";
import { generateString } from "@/common/helper";
import { Promo } from "@/types/Promo";
import moment, { Moment } from "moment";
import { createPromo, loadPromo, updatePromo } from "@/api/promos";

@Component({
  components: {
    ValidationObserver,
    InputWithValidation
  }
})
export default class Login extends Vue {
  @Prop() private id!: number;

  title = "Create promo";
  buttonText = "Create";
  loading = false;
  disabledButton = false;
  range = "";
  dateError = "";
  formatTo = "YYYY-MM-DD 00:00:00";

  promo: Promo = {
    code: "",
    discount: 1,
    startedAt: moment().format(this.formatTo),
    endedAt: moment().format(this.formatTo),
    multiple: true,
    duration: "once",
    durationMonth: 1,
    status: true
  };

  mounted() {
    if (typeof this.id !== "undefined") {
      this.title = "Update";
      this.buttonText = "Update";
      this.loadPromo();
    }
  }

  async loadPromo() {
    const { data } = await loadPromo(this.id);
    this.promo = data;
    this.title = `Update ${this.promo.code}`;
  }

  onChange(value: Moment[]) {
    this.promo.startedAt = value[0].format(this.formatTo);
    this.promo.endedAt = value[1].format(this.formatTo);
  }

  async onSubmit() {
    this.loading = true;
    this.disabledButton = true;
    let title = "Create";
    let message = "Promo code has been created";
    if (typeof this.id !== "undefined") {
      title = "Update";
      message = "Promo code has been updated";
      await updatePromo(this.id, this.promo);
    } else {
      await createPromo(this.promo);
    }
    this.openNotificationWithIcon(title, message);
    this.loading = false;
    this.disabledButton = false;
    this.$router.push({ name: "Promos" });
  }

  openNotificationWithIcon(title: string, message: string) {
    this.$notification.success({
      message: title,
      description: message
    });
  }

  generateCode() {
    this.promo.code = generateString(7);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moment = (args: any) => moment(args);
}
